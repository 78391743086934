//
// pricing.js
// Theme module
//

import { CountUp } from 'countup.js';


document.getElementById('andalasLightScans').addEventListener('change', function() {
  if(this.value == 1){
    const countUp = new CountUp('andalasLightPrice', 99);
    countUp.start();
  }else if(this.value == 2){
    const countUp = new CountUp('andalasLightPrice', 199);
    countUp.start();
  }else if(this.value == 3){
    const countUp = new CountUp('andalasLightPrice', 299);
    countUp.start();
  }
});

document.querySelectorAll(".storeClass").forEach((item) => {
  item.addEventListener('change', function() {

    const scanCount = document.getElementById('scanStore').value;
    const timeCount = document.getElementById('scanStore').value;

    if (this.value == 1) {
      const countUp = (timeCount == 4) ? new CountUp('andalasStorePrice', 90) : new CountUp('andalasStorePrice', 85);
      countUp.start();
    }else if (this.value == 2) {
      const countUp = (timeCount == 4) ? new CountUp('andalasStorePrice', 180) : new CountUp('andalasStorePrice', 170);
      countUp.start();
    }
    else if (this.value == 3) {
      const countUp = (timeCount == 4) ? new CountUp('andalasStorePrice', 360) : new CountUp('andalasStorePrice', 340);
      countUp.start();
    }else if (this.value == 4) {
      if(scanCount == 1){
        const countUp = new CountUp('andalasStorePrice', 90);
        countUp.start();
      }else if(scanCount == 2){
        const countUp = new CountUp('andalasStorePrice', 180);
        countUp.start();
      }else if(scanCount == 3){
        const countUp = new CountUp('andalasStorePrice', 360);
        countUp.start();
      }
    }else if (this.value == 5) {
      if(scanCount == 1){
        const countUp = new CountUp('andalasStorePrice', 85);
        countUp.start();

      }else if(scanCount == 2){
        const countUp = new CountUp('andalasStorePrice', 170);
        countUp.start();

      }else if(scanCount == 3){
        const countUp = new CountUp('andalasStorePrice', 340);
        countUp.start();

      }
    }
  });
});


